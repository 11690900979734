/* eslint-disable max-len */
const config = {
  maintenance: process.env.MAINTENANCE || false,
  versionBaseUrl: 'https://storage.googleapis.com/tf-ver-bkt/',
  api: {
    accountServicePath: process.env.ACCOUNT_SERVICE_PATH || '/account',
    vacancyServicePath: process.env.VACANCY_SERVICE_PATH || '/vacancy',
    publishingServicePath: process.env.PUBLISH_SERVICE_PATH || '/vacancy/publish/',
    publishingStatusPath: process.env.PUBLISH_SERVICE_PATH || '/vacancy/publish/vacancy',
    addressLookupURL: process.env.ADDRESS_LOOKUP_URL || '/location/address-lookup',
  },
  datadog: {
    applicationId: '85083962-c469-4e79-96c9-ed20f6514fd8',
    clientToken: 'pub407193a51ee1aba94433a642d302316a',
  },
  vacancy: {
    updateStatusPath: process.env.VACANCY_UPDATE_STATUS_PATH || '/status',
    vacancyFunnelPath: process.env.VACANCY_FUNNEL_PATH || '/funnel',
    createdByUserPath: process.env.CREATED_BY_USER_PATH || '/account/users',
    vacancyExportPath: process.env.CREATED_BY_USER_PATH || '/vacancy/export',
  },
  auth: {
    loginPath: process.env.LOGIN_PATH || '/login',
    refreshTokenPath: process.env.REFRESH_TOKEN || '/refresh',
    authPath: process.env.AUTH_PATH || '/user/login',
    idpConfigPath: process.env.IDP_CONFIG_PATH || '/user/idp/configuration',
    idpBasePath: process.env.IDP_CONFIG_PATH || '/user/idp',
    passwordlessAuthPath: process.env.AUTH_PATH || '/user/passwordless/login',
    startPasswordlessAuthPath: process.env.AUTH_PATH || '/user/passwordless/login/start',
    twoFactorAuthPath: process.env.TWO_FACTOR_AUTH_PATH || '/user/2FA/login',
    logoutPath: process.env.LOGOUT_PATH || '/user/logout',
    forgotPasswordPath: process.env.FORGOT_PASSWORD_PATH || '/reset-password-request',
    zenDeskSSOPath: process.env.ZENDESK_SSO_PATH || '/zendesk/sso',
    zenDeskPath: process.env.ZENDESK_PATH || 'https://talentfunl.zendesk.com/access/jwt',
  },
  account: {
    getUserPath: process.env.GET_USER || '/user',
    getQRCodePath: process.env.GET_QR_CODE_PATH || '/user/2FA/connect/qr',
    enableTwoFactorPath: process.env.ENABLE_TWO_FACTOR_PATH || '/user/2FA/enable',
    disableTwoFactorPath: process.env.DISABLE_TWO_FACTOR_PATH || '/user/2FA/disable',
    checkTwoFactorPath: process.env.ENABLE_TWO_FACTOR_PATH || '/user/2FA/enabled',
    userStatusPath: process.env.USER_STATUS_PATH || '/user/status',
    resetPasswordPath: process.env.RESET_PASSWORD_PATH || '/reset-password',
    loginProfilePath: process.env.LOGIN_PROFILE_PATH || '/login-profile',
    accountTenantPath: process.env.ADMIN_ACCOUNT_STATUS_PATH || '/tenant',
    styleSettingsPath: process.env.STYLE_SETTINGS_PATH || '/account/settings/style',
    talentHubStyleSettingsPath: process.env.STYLE_SETTINGS_PATH || '/tenant/talenthub/config',
    userProfilePath: process.env.USER_PROFILE_PATH || '/user/profile',
    resetPasswordTrustedPath: process.env.RESET_PASSWORD_TRUSTED_PATH || '/user/trusted/reset-password',
    reSyncAccountsPath: process.env.RESET_PASSWORD_TRUSTED_PATH || '/v2/account/reset-account-access',
    lookupUsersForAccountPath: process.env.LOOKUP_USERS_FOR_ACCOUNT || '/v2/user/list',
    tenantAdminUserSearchPath: process.env.TENANT_ADMIN_USER_SEARCH_PATH || '/search',
    tenantAdminUserBasePath: process.env.TENANT_ADMIN_USER_BASE_PATH || '/account/tenant/admin/user',
    idpManagementPath: process.env.TENANT_ADMIN_USER_BASE_PATH || '/account/idp/management',
    registerPath: process.env.REGISTER_PATH || '/v2/register/ats',
  },
  atr: {
    atrApprovalPath: process.env.ATR_APPROVAL_PATH || '/vacancy/atr/approval',
    atrAuthedApprovalPath: process.env.ATR_APPROVAL_PATH || '/vacancy/atr/approval/authed',
    atrRerequestPath: process.env.ATR_REREQUEST_PATH || '/vacancy/atr/approval/re-request',
    atrSearchPath: process.env.ATR_SEARCH_PATH || '/vacancy/atr/admin/search',
    atrAdminPath: process.env.ATR_ADMIN_PATH || '/vacancy/atr/admin',
  },
  company: {
    companyByAccountPath: process.env.COMPANY_BY_ACCOUNT_PATH || '/company/account',
    companyProfilePath: process.env.COMPANY_PROFILE_PATH || '/company/profile',
    companyImagePath: process.env.COMPANY_IMAGE_PATH || '/company/profile/image',
    companyDocumentPath: process.env.COMPANY_DOCUMENT_PATH || '/company/profile/document/public',
  },
  event: {
    eventNotificationsPath: process.env.EVENT_NOTIFICATIONS_PATH || '/event/notifications',
    notificationViewedPath: process.env.EVENT_NOTIFICATIONS_VIWED_PATH || '/event/notifications/view',
  },
  search: {
    vacancySearchPath: process.env.SEARCH_VACANCY_SERVICE_PATH || '/vacancy/search',
    candidateSearchPath: process.env.SEARCH_APPLICANT_SERVICE_PATH || '/application/searchai',
    candidateTypeAheadSearchPath:
      process.env.SEARCH_APPLICANT_TYPE_AHEAD_SEARCH_PATH || '/application/search/typeahead',
    talentMatchPath: process.env.SEARCH_TALENT_POOL_SERVICE_PATH || '/talent-match',
    sharePath: process.env.SEARCH_SHARE_PATH || '/search/share',
    savePath: process.env.SEARCH_SHARE_PATH || '/search/save',
  },
  onboarding: {
    basePath: process.env.ONBOARDING_BASE_PATH || '/candidate/onboarding',
    downloadOnboardingPath: process.env.DOWNLOAD_ONBOARDING_PATH || '/download',
    submitToFlow: process.env.DOWNLOAD_ONBOARDING_PATH || '/flow/process/v1',
    submitToSmart: process.env.DOWNLOAD_ONBOARDING_PATH || '/smart/process/v1',
    submitToProspec: process.env.DOWNLOAD_ONBOARDING_PATH || '/prospec/process/v1',
  },
  edoc: {
    basePath: process.env.EDOC_BASE_PATH || '/candidate/edocs',
    downloadeDocPath: process.env.DOWNLOAD_EDOC_PATH || '/download',
  },
  genericForms: {
    basePath: process.env.EDOC_BASE_PATH || '/candidate/generic-forms',
    downloadeDocPath: process.env.DOWNLOAD_EDOC_PATH || '/download',
  },
  rtw: {
    basePath: process.env.RTW_BASE_PATH || '/rtw',
  },
  personalityTest: {
    basePath: process.env.PT_BASE_PATH || '/pts',
  },
  references: {
    referenceBasePath: process.env.REFERENCE_BASE_PATH || '/candidate/reference',
    refereeBasePath: process.env.REFEREE_BASE_PATH || '/candidate/referee',
    downloadReferencesPath: process.env.DOWNLOAD_REFERENCES_PATH || '/download',
  },
  jobseeker: {
    talentMatchProfilePath: process.env.TALENT_MATCH_PROFILE_PATH || '/jobseeker',
    talentMatchSetStatusPath: process.env.TALENT_MATCH_SET_STATUS_PATH || '/talent-match/jobseeker/status',
    talentMatchSetFavouritePath: process.env.TM_SET_FAVOURITE_PATH || '/talent-match/jobseeker/favourite',
    talentMatchInviteToApply: process.env.TALENT_MATCH_INVITE_TO_APPLY || '/talent-match/interview/invite',
  },
  applications: {
    applicationServicePath: process.env.APPLICATION_SERVICE_PATH || '/application',
    applicationURLPath: process.env.APPLICATION_SERVICE_BUILD_PATH || '/url',
    applicationBuilderPath: process.env.APPLICATION_SERVICE_ADMIN_PATH || '/application-form-builder',
    applicationFilePath: process.env.APPLICATION_SERVICE_FILE_PATH || '/file',
    applicantFilePath: process.env.APPLICANT_SERVICE_FILE_PATH || '/applicant/file',
    applicationPDFDownloadPath: process.env.APPLICATION_SERVICE_FILE_PATH || '/download',
    applicantFunnelUpdatePath: process.env.APPLICANT_FUNNEL_UPDATE_PATH || '/applicant/funnel/update-stage',
    candidateFunnelStageOrderUpdatePath:
      process.env.CANDIDTAE_FUNNEL_STAGE_ORDER_UPDATE_PATH || '/applicant/funnel/update-order',
    applicantFunnelPath: process.env.APPLICANT_FUNNEL_PATH || '/applicant/funnel',
    availableFunnelPath: process.env.AVAILABLE_FUNNEL_PATH || '/available',
    applicantEmailPath: process.env.APPLICANT_EMAIL_PATH || '/applicant/contact/email',
    applicantSmsPath: process.env.APPLICANT_SMS_PATH || '/applicant/contact/sms',
    applicantNotificationPath: process.env.APPLICANT_SMS_PATH || '/notification/candidate',
    applicantTimelinePath: process.env.APPLICANT_TIMELINE_PATH || '/applicant/timeline',
    applicantTagUpdatePath: process.env.APPLICANT_TAG_UPDATE_PATH || '/applicant/tag',
    applicantGetAllTagsPath: process.env.APPLICANT_GET_ALL_TAGS_PATH || '/applicant/tag/management/all',
    applicantTagManagementPath: process.env.APPLICANT_TAG_MANAGEMENT_PATH || '/applicant/tag/management',
    applicantGetCurrentTagsPath: process.env.APPLICANT_GET_CURRENT_TAGS_PATH || '/applicant/tag/applicant-tags',
    candidateVideoTranscriptionPath: process.env.CANDIDATE_VIDEO_TRANSCRIPTION_PATH || '/candidate/video/transcribe',
    applicantNotePath: process.env.APPLICANT_NOTE_PATH || '/applicant/note',
    applicantOtherApplicationPath: process.env.OTHER_APPLICANTION_PATH || '/application/other',
    candidateExportPath: process.env.CANDIDATE_EXPORT_PATH || '/candidate/export',
    candidateGroupAppExportPath: process.env.CANDIDATE_EXPORT_PATH || '/application/downloads',
    exportCandidatesAsPDFPath: process.env.EXPORT_CANDIDATES_AS_PDF_PATH || '/candidate/download',
    candidateProfileSummaryPath: process.env.CANDIDATE_PROFILE_SUMMARY_PATH || '/candidate/profile/summary',
    candidateAnonAccessPath: process.env.CANDIDATE_ANON_ACCESS_PATH || '/candidate/external/access',
    candidateExternalAccessGeneratePath:
      process.env.CANDIDATE_ANON_ACCESS_PATH || '/candidate/external/access/generate',
    moveCandidatePath: process.env.MOVE_CANDIDATE_PATH || '/candidate/migrate',
  },
  admin: {
    accountSearchPath: process.env.ADMIN_ACCOUNT_SEARCH_PATH || '/admin/account',
    accountStatusPath: process.env.ADMIN_ACCOUNT_STATUS_PATH || '/admin/account/status',
    emailTemplateSearchPath: process.env.EMAIL_TEMPLATE_SEARCH_PATH || '/template/email/search',
    emailTemplateAdminPath: process.env.EMAIL_TEMPLATE_SEARCH_PATH || '/template/email',
  },
  publishing: {
    availableChannelsPath: process.env.AVAILABLE_CHANNELS_PATH || '/publishing/available-channels',
    boardsPath: process.env.AVAILABLE_BOARDS_PATH || '/publishing/multiposter/board',
    channelsServicePath: process.env.CHANNELS_SERVICE_PATH || '/publishing',
    vacancyChannelsPath: process.env.VACANCY_CHANNELS_PATH || '/publishing/vacancy',
    jobSiteConfigPath: process.env.JOB_SITE_CONFIG_PATH || '/tenant/jobsite',
    multiPosterAccountPath: process.env.MULTI_POSTER_ACCOUNT_PATH || '/publishing/multiposter/account',
    publisherAccountPath: process.env.MULTI_POSTER_ACCOUNT_PATH || '/publisher/account',
    multiPosterBoardPath: process.env.MULTI_POSTER_BOARD_PATH || '/publishing/multiposter/board',
    externalPublishingPath: process.env.EXTERNAL_PUBLISHING_PATH || '/publishing/vp/publish',
    indeedIntegrationPath: process.env.INDEED_INTEGRATION_PATH || '/integration/indeed/settings/',
  },
  analytics: {
    searchAnalyticsPath: process.env.AVAILABLE_CHANNELS_PATH || '/analytics/search',
  },
  reporting: {
    candidateBasePath: process.env.REPORTING_CANDIDATE_BASE_PATH || '/reporting/candidate',
    candidateCategoryBasePath: process.env.REPORTING_CANDIDATE_BASE_PATH || '/reporting/candidate/category',
    vacancyBasePath: process.env.REPORTING_CANDIDATE_BASE_PATH || '/reporting/vacancies',
    funnelBasePath: process.env.REPORTING_FUNNELE_BASE_PATH || '/reporting/funnel',
    candidateVacancyByDate: process.env.REPORTING_CANDIDATE_VACANCY_BY_DATE || '/vacancy/date',
    candidateInPeriodByGroup: process.env.REPORTING_CANDIDATE_IN_PERIOD_BY_GROUP || '/group',
    funnelStagesByDate: process.env.REPORTING_FUNNEL_STAGES_BY_DATE || '/funnel/stages',
    candidateReferralData: process.env.REPORTING_CANDIDATE_REFERRAL || '/referral/share',
    hiredCandidateByGroup: process.env.REPORTING_HIRED_CANDIDATE_REFERRAL || '/hired/group',
    candidateTotalData: process.env.REPORTING_CANDIDATE_TOTAL || '/total',
    activeVacanciesTotal: process.env.ACTIVE_VACANCIES_TOTAL || '/active/total',
    postedVacancies: process.env.POSTED_VACANCIES_TOTAL || '/posted',
    activeVacanciesByDay: process.env.ACTIVE_VACANCIES_DAY_TOTAL || '/active/day',
    openPositionsByDay: process.env.ACTIVE_VACANCIES_DAY_TOTAL || '/open-positions/day',
    stageTotalPath: process.env.REPORTING_CANDIDATE_TOTAL || '/stage/total',
    averageTimeToStagePath: process.env.REPORTING_CANDIDATE_TOTAL || '/stage/time',
    averageTimeToStageByAccountPath: process.env.REPORTING_CANDIDATE_TOTAL || '/stage/time/group',
    rejectionReasonCountInPeriodPath: process.env.REPORTING_CANDIDATE_TOTAL || '/rejection-reason',
    funnelStateChangeByStage: process.env.REPORTING_FUNNEL_STATE_CHANGE_BY_STAGE || '/change/stage',
    exportDataBasePath: process.env.EXPORT_DATA_BASE_PATH || '/export',
    accountActivityReportBasePath: process.env.EXPORT_DATA_BASE_PATH || '/reporting/account-activity',
    activeVacancyReportBasePath: process.env.EXPORT_DATA_BASE_PATH || '/reporting/active-vacancy',
    postedVacancyReportBasePath: process.env.EXPORT_DATA_BASE_PATH || '/reporting/posted-vacancy',
  },
  feedback: {
    endpoints: {
      submit: '/feedback/submit',
      search: '/feedback/search',
    },
  },
  forms: {
    createVacancyForm: process.env.CREATE_VACANCY_FORM || '/form/create-vacancy',
    flexiForm: process.env.FLEXI_FORM || '/flexi-form',
    flexiFormView: process.env.FLEXI_FORM_VIEW || '/flexi-form/view',
    candidateReferralData: process.env.REPORTING_CANDIDATE_REFERRAL || '/referral/share',
    flexiFormGenerateToken: process.env.FLEXI_FORM_GENERATE_TOKEN || '/flexi-form/completed/edit/token',
    questionSearchPath: process.env.QUESTION_SEARCH_PATH || '/form/questions/search',
    formStyles: process.env.FORM_THEME || '/forms/theme',
  },
  file: {
    getFile: process.env.FILE_GET_FILE || '/file/get',
    getVTTFile: process.env.FILE_GET_VTT_FILE || '/video/vtt',
    getLargeFile: process.env.FILE_GET_LARGE_FILE || '/file/get/multipart/',
  },
  dashboard: {
    candidatesByFunnelStagePath: process.env.CANDIDTAES_BY_FUNNEL_STAGE || '/dashboard/funnel/candidates/by-stage',
    userNotificationsActivityForPeriodPath:
      process.env.USER_NOTIFICIATION_ACTIVITY_FOR_PERIOD || '/dashboard/notification/email-activity/user',
    userFunnelChangeCountForPeriodPath:
      process.env.USER_FUNNEL_CHANGE_COUNT_FOR_PERIOD || '/dashboard/funnel/change-count/group',
    funnelStageByCategoryForPeriodPath:
      process.env.FUNNEL_STAGE_BY_CATEGORY_FOR_PERIOD_PATH || '/reporting/candidate/stage-by-category',
    vacancyCountByStatusPath: process.env.VACANCY_COUNT_BY_STATUS_PATH || '/dashboard/vacancy/status',
  },
  templates: {
    email: process.env.TEMPLATES_EMAIL || '/template/email',
    form: process.env.TEMPLATES_FORM || '/flexi-form/view/template',
  },
  scheduling: {
    events: process.env.EVENTS || '/scheduling/calendar/view',
    slotPath: process.env.SLOT_PATH || '/scheduling/calendar/slot',
    syncCalendarGooglePath: process.env.CALENDAR_SYNC_GOOGLE_PATH || '/scheduling/calendar/sync/google',
    getSyncedCalendarsPath: process.env.GET_SYNCED_CALENDARS_PATH || '/scheduling/calendar/sync',
    syncCalendarGoogleCallbackPath:
      process.env.CALENDAR_SYNC_GOOGLE_CALLBACK_PATH || '/scheduling/calendar/sync/callback',
    availableSlotPath: process.env.AVAILABLE_SLOT_PATH || '/available/count',
    eventAvailabiltyPath: process.env.EVENT_AVAILABILTY_PATH || '/scheduling/calendar/slot/availability',
    participantCheckPath: process.env.PARTICIPANT_CHECK_PATH || '/interview/video/check/participants',
    joinVideoMeetingPath: process.env.JOIN_VIDEO_MEETING_PATH || '/scheduling/video/join',
    descriptionPath: process.env.DESCRIPTION_PATH || '/scheduling/event/description',
    eventTypePath: process.env.EVENT_TYPE_PATH || '/scheduling/event/type',
    sendInvitePath: process.env.SEND_INVITE_PATH || '/scheduling/invite',
    bookingPath: process.env.BOOKING_PATH || '/scheduling/invite/slot',
    inviteStatusPath: process.env.INVITE_STATUS_PATH || '/scheduling/invite/status',
    inviteNotesPath: process.env.INVITE_NOTES_PATH || '/scheduling/invite/notes',
    downloadInviteNotesPath: process.env.DOWNLOAD_INVITE_NOTES_PATH || '/scheduling/invite/notes/download',
    cancelInvitePath: process.env.CANCEL_INVITE_PATH || '/scheduling/invite/cancel',
  },
  locations: {
    locationPath: process.env.LOCATION_PATH || '/location/defined',
  },
  accountManagement: {
    accountPath: process.env.ACCOUNT_PATH || '/v2/account',
    userPath: process.env.USER_PATH || '/v2/user',
  },
  permissions: {
    permissionGroupsPath: process.env.PERMISSION_GROUPS_PATH || '/permission-groups',
    permissionsPath: process.env.PERMISSIONS_PATH || '/permissions',
  },
  integrations: {
    fourth: {
      dataPath: process.env.FOURTH_CSV_DATA_PATH || '/fourth/predefined-data',
      userPath: process.env.FOURTH_USER_PATH || '/fourth/user',
      submitPath: process.env.FOURTH_SUBMIT_PATH || '/fourth/submission',
      statusPath: process.env.FOURTH_STATUS_PATH || '/fourth/submission/status',
      globalConfigPath: process.env.FOURTH_GLOBAL_CONFIG_PATH || '/fourth/predefined-data/config',
    },
    s4: {
      globalConfigPath: process.env.S4_GLOBAL_CONFIG_PATH || '/s4/config/v1',
      payTypePath: process.env.S4_PAY_TYPE_PATH || '/s4/config/v1/pay/types',
      positionsPath: process.env.S4_POSITIONS_PATH || '/s4/data/v1/site',
      siteMapPath: process.env.S4_SITE_MAP_PATH || '/s4/user/v1/sites',
      submitPath: process.env.S4_SUBMIT_PATH || '/s4/submission/v1',
      userPath: process.env.S4_USER_PATH || '/s4/user/v1',
    },
    smart: {
      basePath: process.env.SMART_BASE_PATH || '/smart/process/v1',
    },
    sapnl: {
      basePath: process.env.SAPNL_BASE_PATH || '/integration/sap/nl',
    },
    sap: {
      basePath: process.env.SAP_BASE_PATH || '/integration/sap',
      submitPath: process.env.SAP_SUBMIT_PATH || '/integration/sap/sent',
      resendPath: process.env.SAP_RESEND_PATH || '/integration/sap/resend',
    },
    polaris: {
      userPath: process.env.POLARIS_USER_PATH || '/polaris/user/v1',
      keyMapPath: process.env.POLARIS_KEY_MAP_PATH || '/polaris/user/v1/keys',
      accountKeyMapPath: process.env.POLARIS_ACCOUNT_KEY_MAP_PATH || '/polaris/user/v1/key',
      globalConfigPath: process.env.POLARIS_GLOBAL_CONFIG_PATH || '/polaris/config/v1',
      siteConfigPath: process.env.POLARIS_SITE_CONFIG_PATH || '/polaris/config/v1/account',
      submitPath: process.env.POLARIS_SUBMIT_PATH || '/polaris/submission/v1',
    },
    hollweg: {
      userPath: process.env.POLARIS_USER_PATH || '/pts/user/v1',
      mappingPath: process.env.POLARIS_MAPPING_PATH || '/pts/user/v1/mapping/',
    },
    access: {
      userPath: process.env.ACCESS_USER_PATH || '/access/user/v1',
      submitPath: process.env.ACCESS_SUBMIT_PATH || '/access/submission',
      dataPath: process.env.ACCESS_DATA_PATH || '/access/data',
    },
    sona: {
      submitPath: process.env.SONA_SUBMIT_PATH || '/integration/sona',
      dataPath: process.env.SONA_DATA_PATH || '/integration/sona/data',
    },
    rotaReady: {
      userPath: process.env.ROTA_READY_USER_PATH || '/rotaready/user/v1',
      submitPath: process.env.ROTA_READY_SUBMIT_PATH || '/rotaready/submission',
      dataPath: process.env.ROTA_READY_DATA_PATH || '/rotaready/data',
    },
    peopleHR: {
      basePath: process.env.PEOPLE_HR_BASE_PATH || '/integration/peoplehr',
    },
    tamigo: {
      basePath: process.env.TAMIGO_BASE_PATH || '/integration/tamigo',
      dataPath: process.env.TAMIGO_DATA_PATH || '/integration/data/tamigo',
    },
    nory: {
      basePath: process.env.TAMIGO_BASE_PATH || '/integration/nory',
      dataPath: process.env.TAMIGO_DATA_PATH || '/integration/data/nory',
    },
    ubeya: {
      basePath: process.env.UBEYA_BASE_PATH || '/ubeya/process/v1',
    },
  },
  workflows: {
    workflowPath: process.env.WORKFLOW_PATH || '/workflow',
    tenantWorkflowPath: process.env.WORKFLOW_PATH || '/tenant/workflow',
  },
  funnelManagement: {
    funnelPath: process.env.FUNNEL_PATH || '/funnel/manage',
  },
};
export default config;
