/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';

import { retryableAPICall } from '@API/common-api-utils';
import { saveTemplate, updateTemplate } from '@API/CommsAPI/EmailTemplateAPI';

import TextButton from '@Base/Buttons/TextButton';
import { AccountFormSelect } from '@Base/Account';

import validation from '@JS/utils/validation';
import { checkPermissions } from '@JS/auth/AuthUtils';

import { EmailBodyInput, InlineInput, EmailAttachments } from '../../../../Communication/email';

const allowedToShare = [
  'REJECTION',
  'CANDIDATE_CONTACT',
  'REFEREE_REQUEST',
  'REFERENCE_REQUEST',
  'REFERENCE_REQUEST_CHASE',
  'REFERENCE_REQUEST_CHASE_APPLICANT',
  'REFERENCE_RECEIVED',
  'RIGHT_TO_WORK_REQUEST',
  'RIGHT_TO_WORK_REQUEST_REMINDER_1',
  'RIGHT_TO_WORK_REQUEST_REMINDER_2',
  'PERSONALITY_TEST_REQUEST',
  'PERSONALITY_TEST_REQUEST_REMINDER_1',
  'PERSONALITY_TEST_REQUEST_REMINDER_2',
  'ONBOARDING',
  'ONBOARDING_REQUEST_CHASE',
  'EDOC',
  'EDOC_REQUEST_CHASE',
  'EDOC_CANDIDATE_COPY',
  'GENERIC_FORM',
  'GENERIC_FORM_REQUEST_CHASE',
  'INVITE_TO_INTERVIEW',
  'JOBSEEKER_REGISTRATION',
];

const inputs = [
  {
    id: 'name',
    label: 'Template Name',
    required: true,
    type: 'text',
  },
  {
    id: 'sharedWith',
    label: 'Shared With',
    type: 'accountSharing',
    permissions: ['admin:emails:share'],
    emailTemplateType: ['company'],
  },
  {
    id: 'fromName',
    label: 'From Name',
    required: true,
    type: 'text',
  },
  {
    id: 'replyTo',
    label: 'Reply To Email',
    required: true,
    type: 'email',
    isUser: true,
  },
  {
    id: 'ccAddresses',
    isMulti: true,
    label: 'Cc',
    novalidate: true,
    type: 'email',
    isMulti: true,
    isUser: true,
  },
  {
    id: 'bccAddresses',
    isMulti: true,
    label: 'Bcc',
    novalidate: true,
    type: 'email',
    isMulti: true,
    isUser: true,
  },
  {
    id: 'subject',
    label: 'Subject Line',
    required: true,
    type: 'text',
  },
  {
    id: 'type',
    label: 'Type',
    options: [
      { label: 'Candidate Contact', value: 'CANDIDATE_CONTACT' },
      { label: 'EDocument Request', value: 'EDOC' },
      { label: 'EDocument Request Chase', value: 'EDOC_REQUEST_CHASE' },
      { label: 'EDocument Candidate Complete Copy', value: 'EDOC_CANDIDATE_COPY' },
      { label: 'Flexi-Form Request', value: 'GENERIC_FORM' },
      { label: 'Flexi-Form Request Chase', value: 'GENERIC_FORM_REQUEST_CHASE' },
      { label: 'TalentHub Account Registration', value: 'JOBSEEKER_REGISTRATION' },
      { label: 'TalentHub Passwordless Login Request', value: 'JOBSEEKER_PASSWORDLESS_LOGIN_REQUEST' },
      { label: 'Onboarding Request', value: 'ONBOARDING' },
      { label: 'Onboarding Request Chase', value: 'ONBOARDING_REQUEST_CHASE' },
      { label: 'Rejection', value: 'REJECTION' },
      { label: 'Request Right To Work ID', value: 'RIGHT_TO_WORK_REQUEST' },
      { label: 'Personality Test Request', value: 'PERSONALITY_TEST_REQUEST' },
      { label: 'Personality Test Request Reminder 1', value: 'PERSONALITY_TEST_REQUEST_REMINDER_1' },
      { label: 'Personality Test Request Reminder 2', value: 'PERSONALITY_TEST_REQUEST_REMINDER_2' },
    ],
    required: true,
    type: 'select',
  },
];

const allowedGroups = [{ label: 'Personal', value: 'PERSONAL' }];

inputs.push({
  id: 'group',
  label: 'Scope',
  options: allowedGroups,
  required: true,
  type: 'select',
});

const formDefaultState = {
  attachments: [],
  bccAddresses: [],
  ccAddresses: [],
  fromName: '',
  group: '',
  message: '',
  name: '',
  replyTo: '',
  subject: '',
  submitted: false,
  type: '',
};

const UPDATE_FORM = 'UPDATE_FORM';
const SUBMIT_FORM = 'SUBMIT_FORM';

function formReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_FORM:
      return { ...state, ...payload };
    case SUBMIT_FORM:
      return { ...state, submitted: true };
    default:
      return state;
  }
}

function EmailTemplateForm({
  companyName,
  onSuccess,
  onError,
  data,
  onChange,
  isReadOnly,
  isCopyMode,
  emailTemplate,
  sendBtnLabel,
  activeAccount,
  templateGroup,
}) {
  const formRef = useRef();
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});
  // const [messageText, setMessageText] = useState('');
  const [formState, dispatchForm] = useReducer(formReducer, formDefaultState);

  const {
    id,
    fromName,
    replyTo,
    subject,
    content,
    ccAddresses,
    bccAddresses,
    group,
    type,
    name,
    attachments,
    sharedWith,
  } = emailTemplate || {};

  const messageConfig = {
    id: 'message',
    maxStringSize: 1000000,
    required: true,
  };
  const attachmentsConfig = {
    hasToggle: !isReadOnly,
    id: 'attachments',
    // if there are attached files display input
    isHidden: attachments ? !attachments.length : true,
    label: 'Attachments',
    novalidate: true,
  };

  useEffect(() => {
    if (
      checkPermissions(['admin:emails:company']) &&
      allowedGroups.find((grp) => grp.value === 'COMPANY') === undefined
    ) {
      allowedGroups.push({ label: 'Company', value: 'COMPANY' });
    } else if (checkPermissions(['write:admin']) && allowedGroups.find((grp) => grp.value === 'SYSTEM') === undefined) {
      allowedGroups.push({ label: 'System', value: 'SYSTEM' });
    }
  }, []);

  useEffect(() => {
    const presetFormData = {
      fromName: fromName || companyName,
      replyTo,
      ...data,
    };

    if (id) presetFormData.id = id;
    if (name) presetFormData.name = name;
    if (subject) presetFormData.subject = subject;
    if (content) presetFormData.message = content;
    if (ccAddresses) presetFormData.ccAddresses = ccAddresses;
    if (bccAddresses) presetFormData.bccAddresses = bccAddresses;
    if (allowedGroups.length === 1) presetFormData.group = allowedGroups[0].value;
    if (group) presetFormData.group = group;
    if (type) presetFormData.type = type;
    if (attachments) presetFormData.attachments = attachments;
    if (sharedWith) presetFormData.sharedWith = sharedWith;

    dispatchForm({
      payload: presetFormData,
      type: UPDATE_FORM,
    });
  }, [
    companyName,
    fromName,
    replyTo,
    data,
    isReadOnly,
    ccAddresses,
    bccAddresses,
    content,
    subject,
    id,
    name,
    group,
    type,
    attachments,
    sharedWith,
  ]);

  function handleChange(val, inpId, inpConfig, altVal) {
    if (formState.submitted) {
      const inpErrs = validation([inpConfig], { [inpId]: altVal || val });
      setErrors({ ...errors, ...inpErrs });
    }

    dispatchForm({
      payload: {
        [inpId]: val,
      },
      type: UPDATE_FORM,
    });

    onChange({
      ...formState,
      [inpId]: val,
    });
  }

  async function handleSend(errCount) {
    if (errCount > 0) return;
    setSending(true);

    let dataToSend = { ...formState };
    const { id: stateId } = formState;

    // The current account is always shared with the template
    if (templateGroup === 'company' && checkPermissions(['admin:emails:share'])) {
      if (!formState.sharedWith?.find((account) => account.accountId === activeAccount.id)) {
        dataToSend = {
          ...dataToSend,
          sharedWith: [
            ...(formState.sharedWith || []),
            { accountId: activeAccount.id, accountName: activeAccount.name },
          ],
        };
      }
    }

    let response;
    if (stateId) {
      response = await retryableAPICall(() => updateTemplate(dataToSend));
    } else {
      response = await retryableAPICall(() => saveTemplate(dataToSend));
    }

    if (typeof response === 'string') {
      onError('There was an error saving the template');
    } else {
      onSuccess('Template saved successfully');
    }
    setSending(false);
  }

  function handleSubmit() {
    dispatchForm({ type: SUBMIT_FORM });

    const errObj = validation([...inputs, messageConfig], {
      ...formState,
      // [messageConfig.id]: messageText,
    });
    setErrors(errObj);

    const errCount = Object.values(errObj).filter(({ invalid }) => invalid).length;

    handleSend(errCount);
  }

  const bodyInvalid = 'message' in errors && errors.message.invalid;
  const bodyErrorMsg = bodyInvalid ? errors.message.errors[0] : '';
  const btnText = sendBtnLabel || 'Save Email';

  return (
    <div>
      <Form
        className="comms-email-form"
        innerRef={formRef}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <>
          {inputs.map((inp) => {
            const { id: inpId } = inp;

            const invalid = inpId in errors && errors[inpId].invalid;
            const errorMsg = invalid ? errors[inpId].errors[0] : '';

            if (inp.type === 'accountSharing') {
              const hasPermissions = checkPermissions(inp.permissions);
              const isVisible = inp.emailTemplateType.includes(templateGroup);

              if (!hasPermissions || !isVisible || !allowedToShare.includes(formState.type)) return null;

              return (
                <AccountFormSelect
                  className="row"
                  currentAccounts={(formState.sharedWith || []).map(({ accountId, accountName }) => ({
                    id: accountId,
                    name: accountName,
                  }))}
                  labelClassName="col-sm-3 col-form-label"
                  selectClassName="col-sm-9"
                  disableActiveAccount
                  id="sharedWith"
                  isMulti
                  ignoreActiveAccount
                  label="Shared With"
                  onChange={(accountObjs = []) => {
                    const accounts = accountObjs.map(({ value, label }) => ({
                      accountId: value,
                      accountName: label,
                    }));
                    handleChange(accounts, inpId, inp);
                  }}
                />
              );
            }
            return (
              <InlineInput
                {...inp}
                key={inpId}
                errorMessage={errorMsg}
                invalid={invalid}
                isHidden={(formState.id || isCopyMode) && (inp.id === 'group' || inp.id === 'type')}
                isReadOnly={isReadOnly}
                onChange={(val) => handleChange(val, inpId, inp)}
                value={formState[inpId]}
              />
            );
          })}
          <EmailAttachments
            files={formState.attachments.map((file) => ({
              ...file,
              data: file.data,
              id: file.id,
              name: file.fileName,
              type: file.contentType,
            }))}
            id={attachmentsConfig.id}
            isHidden={false}
            isToggled
            label={attachmentsConfig.label}
            multiple
            onChange={(files) => {
              const normalizedFiles = files.map(({ name: fileName, type: contentType, id: fileId, dataUrl }) => ({
                contentType,
                data: dataUrl,
                fileName,
                id: fileId,
              }));
              handleChange(normalizedFiles, attachmentsConfig.id, attachmentsConfig);
            }}
          />
          {!sending ? (
            <div>
              <EmailBodyInput
                errorMsg={bodyErrorMsg}
                invalid={bodyInvalid}
                isReadOnly={isReadOnly}
                // eslint-disable-next-line no-unused-vars
                onChange={(val, plainText) => {
                  // setMessagePlainText(plainText);
                  handleChange(
                    val,
                    messageConfig.id,
                    messageConfig,
                    // plainText,
                  );
                }}
                value={formState.message}
              />
            </div>
          ) : (
            <FormGroup>
              <div className="ql-container ql-snow">
                <div className="ql-toolbar" style={{ minHeight: '42px' }} />
                <div className="ql-editor" />
              </div>
            </FormGroup>
          )}
        </>
        <TextButton disabled={sending} isLoading={sending} label={sending ? 'Saving...' : btnText} type="submit" />
      </Form>
    </div>
  );
}

EmailTemplateForm.propTypes = {
  actionType: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  emailTemplate: PropTypes.shape(),
  isCopyMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSend: PropTypes.func,
  onSuccess: PropTypes.func,
  sendBtnLabel: PropTypes.string,
  templateGroup: PropTypes.string,
};

EmailTemplateForm.defaultProps = {
  actionType: '',
  data: {},
  emailTemplate: null,
  isCopyMode: false,
  isReadOnly: false,
  onChange: () => {},
  onError: () => {},
  onSend: () => {},
  onSuccess: () => {},
  sendBtnLabel: 'Save Email Template',
  templateGroup: '',
};

function mapStateToProps(state) {
  const {
    companyData: {
      companyDetails: { companyName, contactEmail },
    },
    userData: {
      userDetails: { activeAccountId, activeAccountName },
    },
  } = state;
  return { companyName, contactEmail, activeAccount: { id: activeAccountId, name: activeAccountName } };
}

export default connect(mapStateToProps)(EmailTemplateForm);
